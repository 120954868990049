import React from "react";

import Layout from "../components/layout";
import Slider from "../components/slider";
import { Box, Text, Stack, _hover, Flex } from "@chakra-ui/core";
import PageWrapper from "../components/page.wrapper";
import OurServices from "../components/ourServices";
import B2bService from "../components/b2bService";
import Bio from "../components/bio";



const IndexPage = () => (
  <Layout>
	<Box style= {{marginTop: "-56px"}} className="banner-slide">	
    	<Slider dir="slides" />
	</Box>
    <Box>
      <Box textAlign="center" px="3" py="10" bg="gray.100" color="gray.800" className="square-head">
        <Text pt={5} fontSize="3xl">
          Welcome to <strong>Square Medical & Health Care Services</strong>
        </Text>
        <Text fontSize="xl">Special Care of You & Your Family's Health</Text>
      </Box>

      <Box py={5} bg="#4A5568">
        <PageWrapper textAlign="center" py="6" className="services-head">
          <Text fontSize="3xl" color="white">
            Our Services @ Home
          </Text>
        </PageWrapper>
      </Box>
      <OurServices className="" />

      <Box textAlign="center" py="12" bg="gray.600" className="b2services-head">
        <Text fontSize="3xl" color="white" mb={5}>
          B2B Services
        </Text>

        <B2bService className="b2images"/>
      </Box>
    </Box>

    <Box className="container bio-row" mt="5">
      <PageWrapper className="row">
        <div className="heading-title text-center">
          <h2 >Medical Experts and Paramedical Staff</h2>
        </div>
        <Bio />
      </PageWrapper>
    </Box>

    <Box className="about-row">
      <Box className="about-us">
        <Box className="about-title text-center">
          <h2>About Us</h2>
        </Box>
        <Flex className="about-para">
          <Box className="about-col">
            <p>
              <b>Square Medical and Health Care Services</b> run by{" "}
              <b>Ex-SERVICEMEN</b> who served in <b>Indian Armed Forces</b> for
              20+ years in Fields, High altitude and peace location, where they
              trained to work in any circumstances and their services ends at
              middle age.
              <br></br>
              <br></br>
              Having good hands on experience in Battle field Nursing assistant
              and render their services in abroad Defence Services also, along
              with this they are expertise in online health care companies more
              than 3 years experience and close contact in the field , face to
              face customer interaction to Providing health care service at the
              doorstep of the customers.
              <br></br>
              <br></br>
              Square Medical and Health care offers
              all major health care services at the customer’s doorstep.
            </p>
          </Box>
          <Box className="about-col about-content">
            <p>
              To name a few, Diagnostics ( All Health check up package's),
              Nursing services ( Bed Assistant and Nursing services at Home),
              Physiotherapy Services at home ,Medical Equipment's on rent and
              sales, Medicines supplies in bulk ,Vaccination all Types, on line
              Doctor consultation, Medical fitness health checkups and corporate
              wellness etc.
              <br></br>
              <br></br>
              Can now all be availed from the customer’s location.
              Square Medical and Health care services having wide range of
              services are available to the employees as well as to their
              respective families members can also availed Health check up
              packages during the camp activity. The services are provided at
              the right time with a very nominal cost. Square Medical and Health
              care services will either deliver the service at home/work place
              or facilitate the person.
            </p>
          </Box>
        </Flex>
      </Box>
    </Box>

    
  </Layout>
);

export default IndexPage;