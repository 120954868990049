import React from 'react'
import { Grid, Box, Text } from '@chakra-ui/core'
import { useStaticQuery, graphql } from 'gatsby'

const doctors = {
    "first": {
        title: "Dr. DY Naidu",
        designation: "MBBS, AFIH",
    },
    "second": {
        title: "Dr. V Vidya",
        designation: "MBBS",
    },
    "third": {
        title: "Shekar Ramagiri",
        designation: "BPT, Sr.Physiotherapist",
    },
	"forth": {
		title: "MD.Bilal",
		designation: "MPT (Sports), MIAP",
}
	 
}

const Bio = (props) => {

    const { allFile } = useStaticQuery(
        graphql`
      query {
        allFile(
          sort: { fields: name, order: DESC }
          filter: { relativeDirectory: { eq: "bio" } }
        ) {
          edges {
            node {
              id
              name
              publicURL
            }
          }
        }
      }
    `
    )
    // console.log({ allFile })
    const { edges = [] } = allFile || {}

    const getPerson = (identifier) => {
        const nameSplit = (identifier || "").split("_");
        const key = nameSplit[nameSplit.length - 1];
        return doctors[key] || {}
    }
    return <Grid templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(4, 1fr)"]} gap={1}  {...props}>
        {edges.map(({ node }, idx) => <Box mx="3">
            <div className="team-member">
                <Box className="team-img" p={8} pb={0} >
                    <img src={node.publicURL} alt="team member" className="img-responsive" />
                </Box>
                {/* <div className="team-hover">
                    <div className="desk">
                        <h4>{getPerson(node.name).title}</h4>
                        <p>I love to introduce myself as a best doctor.</p>
                    </div>
                </div> */}
            </div>
            <div className="team-title">
                <h5>{getPerson(node.name).title}</h5>
                <span>{getPerson(node.name).designation}</span>
            </div>
        </Box>)}
    </Grid>
}

const ServiceInfo = ({ identifier }) => {
    const nameSplit = (identifier || "").split("_");
    const key = nameSplit[nameSplit.length - 1];
    const data = doctors[key] || {}
    return <Box p={3} bg="white" color="gray.500">
        <Text fontSize="xl"><strong>{data.title}</strong></Text>
    </Box>
}

export default Bio
