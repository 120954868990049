import React from 'react'
import { Grid, Box, Text } from '@chakra-ui/core'
import { useStaticQuery, graphql } from 'gatsby'

const services = {
    "rehabilation": {
        title: "Rehabliation at Auth Centre"
    },
    "transactional-care": {
        title: "Transactional Care"
    },
    "icu-care": {
        title: "Advanced/Specialized Care"
    },
    "physiotherapy": {
        title: "Physiotherapy"
    },
    "medical-supply": {
        title: "Medical Equipment"
    },
    "doctor-consultant": {
        title: "Dr E Consultation"
    },
    "card-nursing": {
        title: "Nursing Care"
    },
    "bed-attender": {
        title: "Bed Side Care"
    },
    "baby-care": {
        title: "Baby Care"
    }
}

const OurServices = (props) => {

    const { allFile } = useStaticQuery(
        graphql`
      query {
        allFile(
          sort: { fields: name, order: DESC }
          filter: { relativeDirectory: { eq: "our-services" } }
        ) {
          edges {
            node {
              id
              name
              publicURL
            }
          }
        }
      }
    `
    )
    // console.log({ allFile })
    const { edges = [] } = allFile || {}
    return <Grid templateColumns={[ "repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(4, 1fr)"]} gap={1}  {...props} className="b2images">
        {edges.map(({ node }, idx) => <Box key={node.id} className="services-col">
            <Box height="220px" style={{
                background: `url(${node.publicURL}) no-repeat center / cover`
            }}></Box>
            <ServiceInfo identifier={node.name} />
        </Box>)}
    </Grid>
}

const ServiceInfo = ({ identifier }) => {
    const nameSplit = (identifier || "").split("_");
    const key = nameSplit[nameSplit.length - 1];
    const data = services[key] || {}
    return <Box p={3} bg="gray.200" color="gray.600">
        <Text fontSize="xl"><strong>{data.title}</strong></Text>
    </Box>
}

export default OurServices
