import React from 'react'
import { Grid, Box, Text } from '@chakra-ui/core'
import { useStaticQuery, graphql } from 'gatsby'

const services = {
    "nurses-for-health-care": {
        title: "Nurse for OHC and Health Centre"
    },
    "insurance-checkup": {
        title: "Insurance Checkup"
    },
    "medical-fitness": {
        title: "Medical Fitness Checkup"
    },
    "vaccinations": {
        title: "Vaccinations"
    },
    "health-checkup": {
        title: "Health Checkup Packages"
    }
}

const B2bService = (props) => {

    const { allFile } = useStaticQuery(
        graphql`
      query {
        allFile(
          sort: { fields: name, order: DESC }
          filter: { relativeDirectory: { eq: "b2b-services" } }
        ) {
          edges {
            node {
              id
              name
              publicURL
            }
          }
        }
      }
    `
    )
    // console.log({ allFile })
    const { edges = [] } = allFile || {}
    return <Grid templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"]} gap={1}  {...props} className="b2services-row">
        {edges.map(({ node }, idx) => <Box key={node.id} className="b2services-col">
            <Box height="220px" style={{
                background: `url(${node.publicURL}) no-repeat center / cover`
            }}></Box>
            <ServiceInfo identifier={node.name} />
        </Box>)}
    </Grid>
}

const ServiceInfo = ({ identifier }) => {
    const nameSplit = (identifier || "").split("_");
    const key = nameSplit[nameSplit.length - 1];
    const data = services[key] || {}
    return <Box p={3} bg="white" color="gray.500">
        <Text fontSize="xl"><strong>{data.title}</strong></Text>
    </Box>
}

export default B2bService
